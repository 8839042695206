import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './productBlock.css'

function ProductBlock(products,...react) {
  
const handleClick = (id) => {
    window.location.href = "/product/" + id;
}

  if(products!=null ){
        
    if(Array.isArray(products.products) || Array.isArray(products) ){
      
    return (
      <Container fluid>
        <Row >
        {products.products.map(function(a) {
          return (
            <Col sm  xs={12} md={3} key={a.id} onClick={() =>handleClick(a.id)} className="d-flex p-3">
              <Card style={{ width: '18rem' }} className="w-100">
                <Card.Img variant="top" src={process.env.PUBLIC_URL +"/images/products/" + a.imageUrl}  />
                <Card.Body>
                    <Card.Text>{a.name}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
        </Row>
      </Container>
    );
   }
  }
  return(<></>);
}
  
  export default ProductBlock;

  /*
  onClick={window.location.href = "/product/" + a.id}
  */