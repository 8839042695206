import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {fetchData} from '../services/fetchData';
import ProductBlock from '../components/productBlock';

function Categories() {
   const [product, setProduct] = useState([{
     "id": 0,
     "name": "",
     "description": "",
     "imageUrl": "",
     "cena": 0.0,
     "category": 0 
   }]);

 
  const  {id} = useParams();

  useEffect(() => {
    let url = process.env.REACT_APP_PRODUCT_URL
    const getData = async (id) => {
      const fetchedData = await fetchData(url + "products.json");
      var filteredProds = [];
      for(let a = 0; a < fetchedData.length; a++)
      {
         if(id === fetchedData[a].category.toString()){
           filteredProds.push(fetchedData[a])
        }
      }
      setProduct(filteredProds);
    };
    console.log(id)
    getData(id);

   
  }, [id]);

  
  return (
    <ProductBlock products={product} />
  )  
}

export default Categories;
