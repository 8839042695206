import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import "bootstrap/dist/css/bootstrap.css";

import Home from '../pages/home';
import Atomy from '../pages/atomy';
import Product from '../pages/product';
import Categories from '../pages/categories';
import Login from '../pages/Login'
import AuthProvider from '../hooks/AuthProvider';
import Logout from '../pages/Logout';
import Signup from '../pages/Signup';
import ForgotPassword from '../pages/ForgotPassword';
import NotFound from '../pages/NotFound';
import UserDetail from '../pages/UserDetail';

function App() {
  return (
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<MainLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/atomy" element={<Atomy />} />
                <Route path="/:id" element={<Categories />} />
                <Route path="/product/:id" element={<Product />} />
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="signup" element={<Signup />} />
                <Route path="forgot" element={<ForgotPassword />} />
                <Route path="me" element={<UserDetail />} />
                <Route path="not-found" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          </AuthProvider>
        </BrowserRouter>
    
  );
}

export default App;
