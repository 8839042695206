import { Outlet } from 'react-router-dom';

import Footer from '../components/footer';
import Menu from '../components/menu';
import WhatsAppWidget from 'react-whatsapp-chat-widget';
import 'react-whatsapp-chat-widget/index.css';

function MainLayout() {
  return (
    <>
      <Menu />
      <Outlet />
      <WhatsAppWidget
			phoneNo="+420774420768"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={true}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Zdravím, můžu vás poprosit o informaci?"
			iconSize="40"
			iconColor="white"
			iconBgColor="#176b5b"
			headerIcon="/favicon.ico"
			headerIconColor="pink"
			headerTxtColor="black"
			headerBgColor="#176b5b"
			headerTitle="Atomy Amruta Praha"
			headerCaption="Česky, English, Deutsch, Русски"
			bodyBgColor="#bbb"
			chatPersonName="Support"
			chatMessage={<>Jak mohu pomoci?</>}
			footerBgColor="#999"
			placeholder="pošlete zprávu.."
			btnBgColor="#176b5b"
			btnTxt="Začít WhatsUp Chat"
			btnTxtColor="black"
		/>
      <Footer />
    </>
  );
}

export default MainLayout;
