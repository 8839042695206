import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {fetchData} from '../services/fetchData';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Simmilar from '../components/simmilar';
import '../components/hero.css'


function Product() {
   const [product, setProduct] = useState({
     "id": 0,
     "name": "",
     "description": "",
     "imageUrl": "",
     "cena": 0.0
   });

   const [simmilarity, setSimmilarity] = useState([]);

 
  const  {id} = useParams();

  useEffect(() => {
    let url = process.env.REACT_APP_PRODUCT_URL
    const getData = async (id) => {
      const fetchedData = await fetchData(url + "products.json");
      
      for(let a = 0; a < fetchedData.length; a++)
      {
         if(id === fetchedData[a].id.toString()){
          setProduct(fetchedData[a]);
          //simmilar
          if(fetchedData[a].simmilar){
             setSimmilarity(fetchedData[a].simmilar);
          }
        }
      }
    };
    getData(id);

   
  }, [id]);

  
  return (
    
    <Container  fluid="md">
        <Row className="justify-content-center align-self-center">
            <Col sm ><img style={{ height: '30rem' }} src={process.env.PUBLIC_URL +"/images/products/" + product.imageUrl} alt={product.name} /></Col>
            <Col sm ><div><h3>{product.name}</h3> <br/><span dangerouslySetInnerHTML={{__html: product.description}} /></div></Col>
        </Row>
        <Row className="justify-content-center align-self-center">
          <Col  xs={8} >
            <Simmilar prod={simmilarity}  />       
          </Col>
        </Row>
        
    </Container>    
    
  )  
}

export default Product;