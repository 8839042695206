import { Carousel } from "react-bootstrap";
import CarouselCard from "./carouselcard";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./hero.css"




function Hero(slidedata, ...react) {
  
  if(slidedata!=null ){
    if(Array.isArray(slidedata.slidedata) && slidedata.slidedata.length>2){
      var prods = slidedata.slidedata;
      var arr = [];
      var arr3 = [];
      for(let i=0; i<prods.length;i++ ){
        arr3.push(prods[i]);
        if(((i+1) % 3) === 0 ){
          arr.push(arr3);
          arr3 = [];
        }
      }

      return (
        <div className="bg-dark bg-opacity-25 container-fluid">
          <Carousel style={{ height: 350 }}>
          {arr.map((a, index, array) => {
            return (
              <Carousel.Item style={{ height: "23rem" }} key={a.id}>
              <Container fluid className="centercarusel">
                <Row >
                  {a.map((aa) => {
                  return (<Col sm  xs={12} md={3} key={aa.id}  className="d-flex p-3"><CarouselCard carouselData={aa} /></Col>);
                  })}
                </Row>
              </Container>  
          </Carousel.Item>
            );
            }
          )}
          </Carousel>
        </div>
      );
    }
  }
  return(<></>);
}

export default Hero;
