import React, { useState, useEffect } from 'react';
import ProductBlock from "./productBlock";
import {fetchData} from '../services/fetchData';

const Simmilar = ({prod} ) => {
    
    const [product, setProduct] = useState([{
        "id": 0,
        "name": "",
        "description": "",
        "imageUrl": "",
        "cena": 0.0
      }]);


useEffect(() => {
  let url = process.env.REACT_APP_PRODUCT_URL
  const getData = async (prod) => {
    const fetchedData = await fetchData(url + "products.json");
    var filteredProds = [];
    for(let a = 0; a < fetchedData.length; a++)
    {
       if(prod.includes(fetchedData[a].id)){
         filteredProds.push(fetchedData[a])
      }
    }
    setProduct(filteredProds);
  };
  
  if(prod!=null){
      getData(prod);
   }
  
}, [prod]);

return(
    <ProductBlock products={product} />
);
}

export default Simmilar;

/* //setProduct(filteredProds); */