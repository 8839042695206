import { Card } from "react-bootstrap";
import './carouselcard.css'

function CarouselCard(carouselData, ...react){
    function handleClick(){
        window.location.href = "/product/" + carouselData.carouselData.id;
    }
    return (
    <Card style={{ width: "20rem", height: "20rem" }} onClick={handleClick}> 
        <Card.Img variant="top"  src={process.env.PUBLIC_URL +"/images/products/" + carouselData.carouselData.imageUrl}  style={{ height: '100%', objectFit: 'cover' }}/>
        <Card.ImgOverlay>
            <Card.Text>{carouselData.carouselData.name}</Card.Text>
        </Card.ImgOverlay>
    </Card>
);
}

export default CarouselCard;