import React, { useState, useEffect } from 'react';
import { Nav, NavDropdown } from "react-bootstrap";
import useAuth from '../hooks/useAuth';


export default function MenuAditional() {
    const auth = useAuth();
    const user = auth.getSession();
    const [userName, setUserName] = useState("");
    const isauth = auth.isAuth();

    useEffect(() => {
        console.log(isauth);
        if(isauth){
           setUserName(user.name );
        }
       
      }, []);

    if(isauth){
        return (
            <>
            <NavDropdown title={userName} id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Basket</NavDropdown.Item>
              <NavDropdown.Item href="#action4">History orders</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/me">
                Personal Settings
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/logout" >Log-out</Nav.Link>  
            </>
        )
    }else{
        return "";
    }
  
  
  
}
