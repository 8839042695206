const fetchData = async (url) => {
    try {
      const response = await fetch(url,  {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': "*",
          'Access-Control-Allow-Headers': "*"
        }
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchPostData = async (url,data) => {
    try {
      const response = await fetch(url,  {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': "*",
          'Access-Control-Allow-Headers': "*"
        },
        body: data
      });
      return response;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  
  export  {
    fetchData, fetchPostData,
  };
  