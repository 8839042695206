import React, { useState, useEffect } from 'react';
import { Nav, Navbar, NavDropdown, Overlay, Tooltip } from "react-bootstrap";
import { ReactComponent as Logo } from "../logo.svg";
import {fetchData} from '../services/fetchData';

import MenuAditional from '../components/menuAditional';
import "bootstrap/dist/css/bootstrap.min.css";
import "./menu.css";
//import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

export default function Menu() {
 // const [showOverlay, setShowOverlay] = useState(false);
  //const [overlayTarget, setOverlayTarget] = useState(null);


/*
  const handleOverlayToggle = (event) => {
    setShowOverlay(!showOverlay);
    setOverlayTarget(event.target);
  };
  */
  const [menu, setMenu] = useState([{
    "label": "",
    "url": ""
}]);

  useEffect(() => {
    let url = process.env.REACT_APP_PRODUCT_URL
    const getMenu = async () => {
      const fetchedData = await fetchData(url + "menu.json");
      setMenu(fetchedData);
    };

    getMenu();
   

   
  }, []);
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand href="/">
        <Logo
          alt=""
          width="50"
          height="50"
          className="d-inline-block align-top logo"
        />
        Atomy Amruta Praha
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" />
        <Nav>
        {menu.map((a, index, array) => {
            return (
              <Nav.Link eventKey={index} href={a.url} key={index}>
                 {a.label}
            </Nav.Link>  
            );
            }
          )}
         <MenuAditional /> 
         </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
