import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Atomy(){

    return(
        <Container>
            <Row>
                <Col>
                   <img src={process.env.PUBLIC_URL +"/images/atomyheadquater.png"} alt="atomy headquater" />
                   <p>Společnost Atomy byla založena v roce 2009 a je to globální společnost síťového marketingu s přímým prodejem, která má k prosinci 2023 operace ve 27 regionech po celém světě, včetně Koreje. Od svého založení Atomy rychle rostla díky spotřebitelsky orientovanému síťovému marketingu, který přísně dodržuje zásady generálního ředitele Parka Han-gilla „Absolutní kvalita, absolutní cena“. Společnost neúnavně pracovala na vytvoření základu pro udržitelný růst založený na inovativním myšlení, které klade úspěch zákazníka jako svůj hlavní cíl a firemní kulturu založenou na principech, inkluzivním růstu a sdílení.</p>
                   <p>Atomy využívá strategii síťového marketingu orientovanou na spotřebitele. Vybereme výhodné produkty, které jsou vyšší kvality za rozumnější ceny, a prodáváme je prostřednictvím distribučních kanálů, které mohou konkurovat obchodním domům, diskontním obchodům, teleshoppingovým sítím a online nakupování. Jinými slovy, hledáme produkty, které jsou konkurenceschopnější jak kvalitou, tak cenou než podobné produkty distribuované jinými kanály pro prospěch našich zákazníků. Prostřednictvím této strategie slibujeme dosáhnout našeho konečného cíle „Překonat spokojenost zákazníků až k úspěchu zákazníků.“</p>
                </Col>
            </Row>
            <Row>
                <Col>
                   <h3>Amruta Praha Centrum</h3>
                   <img src={process.env.PUBLIC_URL +"/images/atomyheadquater.png"} alt="atomy headquater" />
                   <p>Jsme tu pro vás<br/>
                     Milady Horákové 64/101<br/>
                     160 00<br/>
                     Praha 6-Letná<br/>

                     
                    </p>
                   <p></p>
                </Col>
            </Row>
        </Container>
    );
}
export default Atomy;