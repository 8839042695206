//import { json } from 'stream/consumers';
import {fetchPostData} from '../services/fetchData';

const keyUser = 'authx.user';
const registeredUsers = new Map([
  ['admin', {
    id: 'uid:0', username: 'admin', email: 'admin@example.com', password: 'qwerty', firstname: 'App', lastname: 'Admin',
  }],
  ['lee', {
    id: 'uid:973236115', username: 'lee', email: 'lee@acme.com', password: '12345', firstname: 'Steve', lastname: 'Lee',
  }],
]);


function setSession(user) {
  var json = {name:user.name, token:user.token, roleid:user.roleid}
  localStorage.setItem(keyUser, JSON.stringify(json));
}

function getSession() {
  if (localStorage.getItem(keyUser) === null) {
    return null;
  } 
 const user = localStorage.getItem(keyUser);

 return JSON.parse(user);
}

function isAuth() {
  return !!getSession();
}

async function login(username, password) {
  
  try {
    const response = await fetch("http://localhost:32768/Login",  {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': "application/json; charset=utf-8",
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Headers': "*",
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
      body: JSON.stringify({email: username, password: password})
    });
        if (!response.ok) {
            // Handle non-OK responses (e.g., 404, 500, etc.)
            return { success: false, message: `Error: ${response.status}`, status: response.status };
        }
        const data = await response.json();
        setSession(data.loginResponse);
        // Process the data as needed
        return { success: true, data };
    } catch (error) {
        // Handle any other errors (e.g., network issues)
        return { success: false, message: `Error: ${error.message}` };
    }
}

async function logout() {
  return new Promise((resolve) => {
    // Using setTimeout to simulate network latency.
    setTimeout(() => {
      localStorage.removeItem(keyUser);
      resolve();
    }, 1000);
  });
}

async function sendPasswordReset() {
  return new Promise((resolve) => {
    // Using setTimeout to simulate network latency.
    setTimeout(() => {
      resolve();
    }, 1000);
  });
}


async function addUser(user) {

  var userdata = {
    first_name: user.firstname,
    last_name: user.lastname,
    email: user.email,
    password: user.password,
    adress: user.street,
    zip: user.zip,
    city: user.city,
    country: "1"}

    try {
      const response = await fetch("http://localhost:32768/api/users/",  {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': "application/json; charset=utf-8",
          'Access-Control-Allow-Origin': "*",
          'Access-Control-Allow-Headers': "*",
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
        body: JSON.stringify(userdata)
      });
          if (!response.ok) {
              // Handle non-OK responses (e.g., 404, 500, etc.)
              return { success: false, message: `Error: ${response.status}`, status: response.status };
          }
          const data = await response.json();
          // Process the data as needed
          return { success: true, data };
      } catch (error) {
          // Handle any other errors (e.g., network issues)
          return { success: false, message: `Error: ${error.message}` };
      }
}

async function getUsers() {
  return new Promise((resolve) => {
    // Using setTimeout to simulate network latency.
    setTimeout(() => {
      const users = Array.from(registeredUsers.values());
      resolve(users);
    }, 1000);
  });
}

// The useAuth hook is a wrapper to this service, make sure exported functions are also reflected
// in the useAuth hook.
export {
  getSession, isAuth, login, logout, sendPasswordReset, addUser, getUsers,
};
