
import React, { useState, useEffect } from 'react';
import ProductBlock from "../components/productBlock";
import Hero from "../components/hero";
import {fetchData} from '../services/fetchData';
import "./home.css"


function Home() {
  const [data, setData] = useState([{
    "id": 0,
    "name": "",
    "description": "",
    "imageUrl": "",
    "cena": 0.0
}]);
const [heroData, setHeroData] = useState([{
  "id": 0,
  "name": "",
  "description": "",
  "imageUrl": "",
  "cena": 0.0
}]);

  useEffect(() => {
    let url = process.env.REACT_APP_PRODUCT_URL
    const getData = async () => {
      const fetchedData = await fetchData(url + "products.json");
      setData(fetchedData);
    };

    getData();

    const getHero = async () => {
      const fetchedData = await fetchData(url+ "slider.json");
      setHeroData(fetchedData);
    };

    getHero();
  }, []);


  return (
    <div className="homecenter">
      <Hero slidedata={heroData}/>
      <ProductBlock products={data}/>
    </div>
  );
}

export default Home;