import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-3">
      <Container>
        <Row>
          <Col md={6}>
            <p>&copy; 2024 Atomy Amruta Praha</p>
          </Col>
          <Col md={6} className="text-md-right">
            <p>Kontact: info@AtomyAmruta.cz</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;